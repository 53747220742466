<script>
	import { showUpgradePreferredModal, notification, data } from '../../stores.js';
	import MailIcon from '../../lib/icon/MailIcon.svelte';
	import PhoneIcon from '../../lib/icon/PhoneIcon.svelte';
	import CloseIcon from '../../lib/icon/CloseIcon.svelte';
	import { _ } from 'svelte-i18n';
	import { onMount } from 'svelte';
	import { putPreferred } from '../../utils/api-calls';
	import { getDisplay, logPlausibleEvent, updateAccounts } from '../../utils/helper';
	import { location } from 'svelte-spa-router';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import FullPageModal from './FullPageModal.svelte';

	onMount(() => {
		$notification = {
			text: '',
			type: ''
		};
	});

	let upgradeAjax = false;
	async function upgrade() {
		try {
			upgradeAjax = true;
			const { id, slug, user_name } = $showUpgradePreferredModal?.upgrade;

			const res = await putPreferred({ preferred: id });

			//End of Wiz Upgrade Funnel
			const isInWizard = !$data?.profile?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
			const contact_slug = $showUpgradePreferredModal?.preferred?.slug;
			const email_domain = $showUpgradePreferredModal?.preferred?.user_name?.split('@')[1];
			//wizard upgrade funnel state is not already sent + is in wizard + is wallet app
			if (
				sessionStorage.wiz_upgrade_funnel === 'wiz_upgrade_prompt' &&
				isInWizard &&
				window.isWalletApp
			) {
				logPlausibleEvent({
					n: 'Wiz Upgrade Success',
					p: { contact_slug, email_domain, upgrade_slug: slug },
					u: $location
				});
				sessionStorage.removeItem('wiz_upgrade_funnel');
			}

			$notification = {
				text: $_('{provider} {label} is now preferred', {
					values: {
						provider: getDisplay(slug),
						label: user_name || ''
					}
				}),
				type: 'success'
			};
			if (Array.isArray($data?.profile?.accounts)) {
				$data.profile.accounts = res.accounts;
				$data = updateAccounts($data); //add sortrank, sortlabel and unverified accounts to accounts array
			}
			$showUpgradePreferredModal = null;
		} catch (err) {
			console.error(err);
			upgradeAjax = false;
		}
	}
</script>

<FullPageModal
	on:close={() => ($showUpgradePreferredModal = null)}
	dataTest="upgrade-preferred-modal"
>
	<button
		aria-label="Close"
		on:click={() => ($showUpgradePreferredModal = null)}
		class="absolute group right-6 top-6"
	>
		<CloseIcon />
	</button>
	<h1 class="font-medium">
		{#if $showUpgradePreferredModal?.linkedSubjects}
			{@html $_(
				'You linked a social provider<br/>associated with your<br/>current preferred login provider'
			)}
		{:else}
			{$_('You linked a social provider')}
		{/if}
	</h1>
	<p class="my-4 text-sm italic opacity-80 w-3/4">
		{#if $showUpgradePreferredModal?.preferred.slug === 'email'}
			{$_('Logging in with a social provider is generally more secure than email')}
		{:else}
			{$_('Logging in with a social provider is generally more secure than phone')}
		{/if}
	</p>

	<h2 class="w-full font-medium text-left block">{$_('Do you want to:')}</h2>

	<!-- svelte-ignore a11y-autofocus -->
	<button
		autofocus
		data-test="change-preferred-btn"
		on:click={upgrade}
		disabled={upgradeAjax}
		class="btn-background w-full h-24 mt-3 flex flex-col {upgradeAjax
			? 'items-center'
			: 'items-start'} justify-center px-3"
	>
		{#if upgradeAjax}
			<SpinnerIcon css="h-5 w-5" />
		{:else}
			<span class="font-medium">{$_('Change preferred login provider to')}</span>
			<div class="mt-2 flex items-center">
				<img
					class="w-9 max-h-[36px]"
					src="https://cdn.hello.coop/images/{$showUpgradePreferredModal?.upgrade?.slug}.svg"
					alt={$showUpgradePreferredModal?.upgrade?.slug}
				/>
				<div class="ml-3 flex flex-col items-start">
					<span>{getDisplay($showUpgradePreferredModal?.upgrade?.slug)}</span>
					<span>{$showUpgradePreferredModal?.upgrade?.user_name}</span>
				</div>
			</div>
		{/if}
	</button>

	<h2 class="w-full my-3 font-medium text-center block uppercase">{$_('Or')}</h2>

	<button
		data-test="keep-preferred-btn"
		on:click={() => ($showUpgradePreferredModal = null)}
		class="btn-border w-full h-24 flex flex-col items-start justify-center px-3"
	>
		<span class="font-medium">{$_('Keep preferred login provider as')}</span>
		<div class="mt-2 flex items-center">
			{#if $showUpgradePreferredModal?.preferred.slug === 'email'}
				<MailIcon size="lg" />
			{:else}
				<PhoneIcon size="lg" />
			{/if}
			<div class="ml-3 flex flex-col items-start">
				<span>{$showUpgradePreferredModal?.preferred?.user_name}</span>
				<span>{$_('with a code')}</span>
			</div>
		</div>
	</button>
</FullPageModal>
